.sales a,.sales a:hover{
    color:#fff;
    text-decoration: none;
}
.sales .addSalesPerson{
    width: 50%;
    margin: auto;
}
@media (max-width:768px){
.sales .addSalesPerson{
    width: 90%;
    margin: auto;
}
}