.loginForm {
	height: 100vh;
	position: relative;
}

.loginForm > div {
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.loginForm .mainHeading {
	color: #707070;
	margin-top: 20px;
	margin-bottom: 80px;
}

.loginForm input {
	display: block;
	width: 60%;
	margin: 10px auto 0;
	border: 1px solid #707070;
	height: 44px;
	padding-left: 10px;
}
.loginForm input::placeholder {
	padding-left: 10px;
}

.loginForm input:focus {
	outline: none;
	background-color: #fff;
}

.loginForm input:-webkit-autofill {
	box-shadow: 0 0 0 30px white inset !important;
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.loginForm .passwordBox {
	position: relative;
}

.loginForm .passwordBox > div {
	position: absolute;
	right: 20%;
	color: #4290f6;
	cursor: pointer;
	margin-top: 16px;
}

.loginForm button {
	margin-top: 16px;
	width: 60%;
	border-radius: 15px;
	background: #cfa79d;
	height: 44px;
	color: #fff;
	border: none;
	outline: none;
	margin-top: 80px;
}
.loginForm .errMsg {
	color: #f32013;
	margin-top: 40px;
}

@media (max-width: 768px) {
	.loginForm {
		height: 70vh;
	}
	.loginForm > div {
		top: 50%;
	}
}
