.liveChatModal .timeStamp{
	font-size: 12px;
	direction: ltr;
}
.liveChatModal h6 {
	text-align: center;
}
.liveChatModal h6 span {
	font-weight: bold;
	font-size: 20px;
}

.liveChatModal {
	position: relative;
}
.liveChatModal .textContainer {
	position: absolute;
	bottom: 5px;
	left: 5%;
	width: 100%;
}
.liveChatModal .textContainer > div {
	position: relative;
	margin-bottom: 20px;
}
.liveChatModal .textContainer input {
	width: 90%;
	margin: 0 auto;
	height: 40px;
	border: 1px solid #cfa79d;
	box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
	border-radius: 50px;
	padding: 0 10px;
}
.liveChatModal .textContainer input:focus {
	outline: none;
}

.liveChatModal .textContainer img {
	position: absolute;
	top: 50%;
	right: 12%;
	transform: translateY(-50%);
	cursor: pointer;
}

.liveChatModal .chatContainer {
	height: 300px;
	overflow-y: scroll;
	padding-bottom: 40px;
}

.liveChatModal .chatContainer::-webkit-scrollbar {
	display: none;
}
.liveChatModal .msgContainer.sender {
	direction: rtl;
	width: fit-content;
	margin: 10px 0 0 auto;
}
.liveChatModal .initials {
	border-radius: 50%;
	height: 40px;
	width: 40px;
	line-height: 40px;
	text-align: center;
	background-color: #707070;
	color: #fff;
	display: inline-block;
	margin: 0 5px;
	transform: translateY(-2px);
}
.liveChatModal .msg {
	border-radius: 21px;
	box-shadow: 0 3px 25px #eaeaea;
	padding: 10px 20px;
	width: fit-content;
	display: inline-block;
}

.liveChatModal .msg.sender {
	background-color: rgba(46, 204, 139, 0.16);
}
.liveChatModal .modal-body .sendBar {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	width: 80%;
}
.liveChatModal textarea {
	background-color: #ededed;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	border-radius: 20px;
	border: none;
	outline: none;
	position: relative;
	resize: none;
	height: 40px;
}

.liveChatModal .modal-body .sendBar > span {
	display: inline-block;
	position: absolute;
	right: 5px;
	top: -5px;
	transform: scale(0.8);
	cursor: pointer;
}
