.clientContactBox {
	background: rgba(207, 167, 157, 0.13);
	width: 85%;
	margin: 0 auto;
	padding: 10px 20px 0;
	color: #cfa79d;
	font-weight: 500;
}
.clientContactBox div:first-of-type div:nth-of-type(2) {
	text-decoration: underline;
}
.clientContactBox p {
	font-weight: lighter;
	color: #999898;
	margin-top: 20px;
}

.clientContactBox .timeBar {
	padding-top: 10px;
}

.adminResponseBox {
	margin-top: 10px;
}
.adminResponseBox textarea {
	resize: none;
	width: 95%;
	height: 200px;
	border: 1px solid #cfa79d;
	margin-left: 10px;
}

.adminResponseBox textarea:focus {
	outline: none;
}
