.websiteWordsModal {
	padding: 20px;
}
.websiteWordsModal input[type="text"],
.websiteWordsModal select {
	height: 40px;
	outline: none;
	border: 1px solid #cfa79d;
}

.websiteWordsModal input[type="text"]:first-of-type {
	width: 60%;
}
.websiteWordsModal .keywordsBox input[type="text"] {
	width: 40%;
}
.websiteWordsModal .addBtn {
	background: #cfa79d;
	color: #fff;
	box-shadow: 4px 6px 10px rgba(186, 186, 186, 0.4);
	border-radius: 26px;
	margin-left: 20px;
	padding: 1px 20px;
}
.websiteWordsModal .textEncapsulationBox {
	margin-top: 20px;
}
.websiteWordsModal .textEncapsulationBox .ant-row > div {
	margin-top: 20px;
}

.websiteWordsModal .textEncapsulation {
	font-size: 13px;
}
.websiteWordsModal .textEncapsulation img {
	margin-left: 5px;
	cursor: pointer;
}

.websiteWordsModal .btnContainer {
	margin-top: 60px;
}
@media (max-width: 991px) {
	.websiteWordsModal .subSpecialityTitle {
		margin-top: 20px;
	}
}
