.sideMenu .ant-menu-item-selected {
	background: #f1f1f1 !important;
	box-shadow: 0 3px -6px rgba(0, 0, 0, 0.16) !important;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
	color: #cfa79d !important;
}

.sideMenu .ant-menu-title-content a:hover {
	color: #576e88 !important;
	text-decoration: none;
}
.sideMenu .ant-menu-item {
	position: relative;
}
.sideMenu .notificationBox {
	position: absolute;
	right: 10px;
	top: 5px;
}

.sideMenu .notificationBox .notificationIcon {
	background-color: red;
	width: 20px;
	height: 20px;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	position: relative;
}
.sideMenu .notificationBox .notificationIcon span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
