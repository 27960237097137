.circledRedCross {
	cursor: pointer;
	margin-left: 10px;
}
.ant-table-pagination-right {
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

