header {
	height: 60px;
	background-color: #cfa79d;
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	position: relative;
}

header span {
	position: absolute;
	top: 50%;
	left: 20px;
	transform: translate(-20px, -50%);
	margin-left: 20px;
}
