.isRepliedDot {
	background: red;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 10px;
}

.message-box{
	max-height: 100px;
	overflow-y: auto;
}