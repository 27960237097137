.reportedCoachModal .coachClientCard {
	background: rgba(207, 167, 157, 0.13);
	width: 85%;
	margin: 0 auto;
}
.reportedCoachModal .coachClientCard label {
	margin-left: 5%;
	font-weight: 500;
	color: #707070;
}

.reportedCoachModal .coachClientCard > div {
	margin-bottom: 10px;
}
.reportedCoachModal .coachClientCard > div > div {
	display: flex;
	padding: 0 5%;
}

.reportedCoachModal .coachClientCard > div > div img {
	border: 2px solid #fff;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}
.reportedCoachModal .coachClientCard > div > div > div {
	margin: 20px 0 0 20px;
	color: #cfa79d;
	font-weight: bold;
	font-size: 14px;
}

.reportedCoachModal .clientComplaint label {
	font-weight: 500;
	color: #707070;
	margin-bottom: 10px;
}
.reportedCoachModal .clientComplaint p {
	font-size: 13px;
	color: #b2b2b2;
	width: 90%;
}
@media (max-width: 768px) {
	.reportedCoachModal .avatarLg {
		width: 60px;
		height: 60px;
	}
}
