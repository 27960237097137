.confirmationModal .text {
	font-size: 18px;
	font-weight: 400;
	text-align: center;
	margin-bottom: 10px;
}
.confirmationModal .ant-modal-footer {
	margin: 0;
	padding: 0;
}
