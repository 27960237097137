body {
	margin: 0px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #707070;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #cfa79d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #cfa79d;
}

.avatarContainer {
	text-align: center;
}
.avatarContainer img,
.avatarContainer div {
	width: 60px;
	border-radius: 50%;
}
.avatarLg {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	background-color: #D5CEC5;
	font-size: 20px;
	font-weight: bold;
}
.switch.ant-switch-checked {
	background-color: #cfa79d !important;
}
.mainColor{
	color: #cfa79d !important;
}
.switch.ant-switch {
	background-color: #C1C1C4;
}
.ant-modal-content .ant-modal-footer {
	border-top: none !important;
}
.ant-modal-content {
	border-radius: 35px !important;
}
.textEncapsulation {
	background: #ae8f7b;
	border-radius: 17px;
	color: #fff !important;
	padding: 10px;
	font-size: 16px;
	margin-left: 4px;
	display: flex;
	justify-content: space-evenly;
}
.textEncapsulation:hover{
	text-decoration: none;
}
.initials {
	width: 60px;
	height: 60px;
	background: #d7f4e8;
	color: #898989;
	border-radius: 50%;
	text-align: center;
	font-size: 19px;
	font-weight: 400;
	position: relative;
	line-height: 60px;
	display: inline-block;
}
.initials > span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
}
.grayBox {
	background-color: #f5f5f5;
	color: #989898;
	display: inline-block;
	margin: 5px;
	padding: 5px 25px;
	border-radius: 17px;
}
.btnContainer {
	margin-top: 10px;
	text-align: center;
}
.ant-table-cell {
	cursor: pointer;
}
.border-danger{
	border:1px solid red
}
.primaryBtn,
.primaryBtn:hover,
.secondaryBtn:focus {
	background: #fff;
	border: none;
	width: 200px;
	color: #cfa79d;
	height: 40px;
}

.secondaryBtn,
.secondaryBtn:hover,
.secondaryBtn:focus {
	background-color: #cfa79d;
	width: 200px;
	color: #fff;
	border-radius: 15px;
	height: 40px;
	border: 1px solid #cfa79d;
}
.timeBar {
	font-weight: 500;
	color: #cfa79d;
	padding: 0 5% 10px;
}
.timeBar > span:first-of-type > span {
	margin-left: 10px;
}

.timeBar > span:nth-of-type(2) {
	float: right;
}
.timeBar > span:first-of-type > span {
	margin-left: 10px;
}

.timeBar > span:nth-of-type(2) {
	float: right;
}
.divider {
	background-color: rgba(196, 139, 48, 0.18);
	width: 100%;
	height: 1px;
}
.react-pdf__Page__annotations.annotationLayer {
	display: none !important;
}
.react-pdf__Page__canvas {
	margin: 0 auto;
}
.cursorPointer {
	cursor: pointer;
}
@media (max-width: 768px) {
	.ant-modal {
		width: 100% !important;
	}
}
.f-14{
	font-size: 14px;
}