.emailConfirmation {
	overflow-x: hidden;
}
.emailConfirmation .logo {
	display: inline-block;
	transform: scale(1.35);
	margin: 15px 25px;
}
.emailConfirmation .logo img{
	width: 74px;
}
.emailConfirmation .logoLg
{
   padding-top: 10x !important;
}
.emailConfirmation .divider {
	height: 1px;
	background-color: #edeeef;
}
.emailConfirmation .label {
	font-size: 22px;
	font-weight: 700;
	position: absolute;
	top: 120px;
	left: 70px;
}
.emailConfirmation form {
	width: 32%;
	margin: 20px auto 10px;
	border-radius: 70px;
	box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
	position: relative;
	height: 550px;
}
.emailConfirmation form .inputBox {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
}
.emailConfirmation form .inputBox label {
	font-weight: 600;
}
.emailConfirmation form .inputBox input {
	border: 1px solid #CFA79D;
}
.emailConfirmation .msg {
	text-align: center;
	margin-top: 20px;
}
.emailConfirmation form .btnContainer {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translate(-50%, -20px);
	width: 100%;
	margin: 0 auto;
	text-align: center;
}

.emailConfirmation form .btnContainer .btn {
	margin-right: 10px;
	border: 1px solid #CFA79D;
	border-radius: 26px;
	padding: 1px 35px !important;
	color: #CFA79D;
	text-align: center;
	font-weight: 600;
}
.emailConfirmation form .btnContainer .btn {
	color: #CFA79D;
}
.emailConfirmation form .btnContainer .btn a:hover {
	color: #CFA79D;
	text-decoration: none;
}

.emailConfirmation form .btnContainer input.btn {
	color: #fff;
	background-color: #CFA79D;
}
@media (max-width: 768px) {
	.emailConfirmation .logo {
		text-align: center;
		display: block;
	}
	.emailConfirmation .divider {
		display: none;
	}
	.emailConfirmation .label {
		top: 80px;
		left: 30px;
	}
	.emailConfirmation form {
		width: 100%;
		box-shadow: none;
		border-radius: 0px;
		height: calc(100vh - 80px);
	}
}
