.addAdminsModal .input {
	display: block;
	width: 80%;
	height: 44px;
	margin: 10px auto;
	border: 1px solid #cfa79d;
	padding-left: 10px;
}
.addAdminsModal .inputErr {
	border: 1px solid #f32013;
}
.addAdminsModal input:focus {
	outline: none;
}
.addAdminsModal input::placeholder {
	padding-left: 10px;
}
.addAdminsModal /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.addAdminsModal input[type="number"] {
	-moz-appearance: textfield;
}
