
.add-event .thumbnail-figure{
    padding: 20px;
    border-radius: 50%;
    box-shadow: 0 24px 48px #1D182A0A;
}
.add-event .uploadedImg-container{
    width: 150px;
    height: 150px;
}
.add-event .uploadedImg-container > img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.add-event .ant-form-item-label{
    font-weight: 600;
    font-size: 20px;
}
.add-event .ant-row.ant-form-item,.add-event .ant-input-number,.add-event textarea,.add-event .ant-select,.add-event .ant-input{
    width: 320px !important;
}
.add-event .ant-form-item-control-input{
    margin: auto;
}

.add-event .ant-upload-list-item{
    display: none;
} 